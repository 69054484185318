import React from 'react'
import Link from 'gatsby-link'
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/es"
import Block from "../components/Block"
import Phone from "../components/Phone"
import Chart from "../components/Chart"
import Typewriter from "../components/Typewriter"
import BoxAnimation from "../components/BoxAnimation"

import LogoLp from "../icons/logo-lp.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const IndexPage = (props) => (
  <Layout 
    location={props.location} 
    title="Agencia creativa online"
    description="lopezdesign es una agencia creativa online para páginas web. Diseñamos y desarrollamos su sitio web profesional, elegante y moderno."
    >
    <Block color="white"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h1>
            <div className="shine">
              <span>lopezdesign</span><span>Desarrollador web</span>
            </div>
            <span className="heading-4">lopezdesign</span>
            <span className="heading-4">mail@lopezdesign.de</span>
          </h1>
        </div>
      }
      right={
        <div className="grid__item width--0  width-desk--1of2">
          <div className="text--right">
            <LogoLp alt="lopezdesign - Logo" title="lopezdesign - Logo"/>
          </div>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2 className="text--big"><span>Diseño</span><span>Elegante</span></h2>
          <ul>
            <li>Desarrollo web</li>
            <li>Diseño UI & UX</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <Phone es/>
        </div>
      }
    />
    <Block color="grayHell"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2>Diseño web</h2>
          <p className="constrain--small">
            Lopez Design es una agencia creativa digital de diseño web. Diseñamos y desarrollamos su sitio web profesional, elegante y moderno.
            Con un diseño responsivo y una buena implementación de la usabilidad, usted presenta su nuevo sitio web en Internet. 
            ¡Elegante y moderno!
          </p>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <BoxAnimation boxClass="rotation">
          <StaticImage
            src="../images/webdesign.png"
            width={580}
            alt="Diseño web - lopezdesign"
            title="Diseño web - lopezdesign"
          />
          </BoxAnimation>
        </div>
      }
    />
    <Block color="black"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2>Desarrollo web</h2>
          <p className="constrain--small">
            Preparamos su proyecto web con la tecnología más moderna. Ya sea con CMS como Contao o completamente programado 
            a mano, tenemos una solución adecuada para usted. Con un código seguro y optimizado llevará su sitio web a un 
            nuevo nivel de rendimiento y eficiencia.
          </p>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <h2 className="text--big"><span>Programar</span><span>es <Typewriter text={'Cool!'}/></span></h2>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom-double">
          <h2>¡Optimizada!</h2>
          <p>Una página web optimizada solo ventajas trae:</p>
          <ul className="text--small">
            <li>Corto tiempo de carga</li>
            <li>Mayor velocidad de carga del sitio web</li>
            <li>Ahorro del volumen de datos móviles</li>
            <li>No te aburres de esperar</li>
            <li>Genera un código limpio</li>
            <li>Mejora la Experiencia de Usuario</li>
            <li>Aumenta la usabilidad</li>
            <li>Mejora el posicionamiento (SEO)</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <Chart/>
          <p className="text--small text--center">Fuente: Google Developers - Page Speed</p>
        </div>
      }
    />
    <Block color="white"
      left={
        <div className="grid__item width-desk--4of12 space--bottom-double">
          <h2>Top Creative</h2>
          <ul>
            <li>Diseño web</li>
            <li>CMS - Contao</li>
            <li>Desarrollo web</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--8of12">
          <StaticImage
            className="maus-case"
            src="../images/maus-case.png"
            width={790}
            alt="Proyecto del ratón - lopezdesign"
            title="Proyecto del ratón - lopezdesign"
          />
        </div>
      }
    />
  </Layout>
)

export default IndexPage
